import { defineStore } from "pinia";

import { z } from "zod";

import { schema } from "~/.nuxt/contracts/src/routes/dashboard/notifications";
import { schema as klaviyoNotificationSchema } from "~/.nuxt/contracts/src/routes/misc/klaviyo-notifications";

export const notificationsResponseSchema =
  schema["/dashboard/notifications"].get.response[200].shape.data;
export const notificationsSchema = notificationsResponseSchema.element;

export const subscribePushNotificationSchema =
  schema["/dashboard/push-notifications/subscribe"].post.body;

export const subscribeOnboardingPushNotificationSchema =
  schema["/dashboard/push-notifications/subscribe/onboarding"].post.body;

export const sendKlaviyoNotificationSchema =
  klaviyoNotificationSchema["/klaviyo/notification"].post.body;

export const useNotificationsStore = defineStore("notifications", {
  state: (): {
    loading: boolean;
    notifications: z.infer<typeof notificationsResponseSchema>;
    notificationToken: string;
    pushNotification: Record<string, any>;
  } => ({
    loading: false,
    notifications: [],
    notificationToken: "",
    pushNotification: {},
  }),
  actions: {
    addPushNotification(notification: Record<string, any>) {
      this.pushNotification = notification;
    },

    removePushNotification() {
      this.pushNotification = {};
    },

    async getNotifications() {
      const response = await this.$client["/dashboard/notifications"].get();
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseJson = await response.json();
      this.notifications = notificationsResponseSchema.parse(responseJson.data);
    },

    async subscribePushNotifications(
      payload: z.infer<typeof subscribePushNotificationSchema>
    ) {
      const response = await this.$client[
        "/dashboard/push-notifications/subscribe"
      ].post({ json: { ...payload } });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      this.notificationToken = payload.deviceToken;
    },

    async subscribeOnboardingPushNotifications(
      payload: z.infer<typeof subscribeOnboardingPushNotificationSchema>
    ) {
      const response = await this.$client[
        "/dashboard/push-notifications/subscribe/onboarding"
      ].post({ json: { ...payload } });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      this.notificationToken = payload.deviceToken;
    },

    async sendKlaviyoNotification(
      body: z.infer<typeof sendKlaviyoNotificationSchema>
    ) {
      const response = await this.$client["/klaviyo/notification"].post({
        json: body,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
  },
});
