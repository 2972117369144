import revive_payload_client_8jyPO2qiR1 from "/data/runners/two/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OsUE1GlVZ3 from "/data/runners/two/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cPYKFtb7lU from "/data/runners/two/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6JWEzUcQQr from "/data/runners/two/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_z8XpejQc5V from "/data/runners/two/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_pDn6tiUuqj from "/data/runners/two/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.23.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_CIHUsPEZjX from "/data/runners/two/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/.nuxt/floating-vue.mjs";
import chunk_reload_client_eprkv8m2HZ from "/data/runners/two/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apexCharts_client_ILYcaWERKg from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/apexCharts.client.ts";
import clerk_client_82tfzadmlf from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/clerk.client.ts";
import dateFns_TubOpgHRRM from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/dateFns.ts";
import fets_ijYEFpGoED from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/fets.ts";
import filters_VNgPjx6zHj from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/filters.ts";
import firebase_Wjz9XrZo01 from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/firebase.ts";
import flags_p3tEupiaZS from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/flags.ts";
import icons_sncVX745v6 from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/icons.ts";
import lottie_q1asFBKAuo from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/lottie.ts";
import mask_Tk3SUcMqzt from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/mask.ts";
import money_JsSmk5CwyU from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/money.ts";
import pinia_client_l2mvTNw9P0 from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/pinia.client.ts";
import places_wGZnNGyAIQ from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/places.ts";
import recaptcha_85gNSCNFUU from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/recaptcha.ts";
import select_tI36lTGDUc from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/select.ts";
import sentry_3AyO8nEfhE from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/sentry.ts";
import vCalendar_client_FEmB2rjZFJ from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/plugins/vCalendar.client.ts";
export default [
  revive_payload_client_8jyPO2qiR1,
  unhead_OsUE1GlVZ3,
  router_cPYKFtb7lU,
  payload_client_6JWEzUcQQr,
  check_outdated_build_client_z8XpejQc5V,
  plugin_vue3_pDn6tiUuqj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CIHUsPEZjX,
  floating_vue_EIcJ7xiw0h,
  chunk_reload_client_eprkv8m2HZ,
  apexCharts_client_ILYcaWERKg,
  clerk_client_82tfzadmlf,
  dateFns_TubOpgHRRM,
  fets_ijYEFpGoED,
  filters_VNgPjx6zHj,
  firebase_Wjz9XrZo01,
  flags_p3tEupiaZS,
  icons_sncVX745v6,
  lottie_q1asFBKAuo,
  mask_Tk3SUcMqzt,
  money_JsSmk5CwyU,
  pinia_client_l2mvTNw9P0,
  places_wGZnNGyAIQ,
  recaptcha_85gNSCNFUU,
  select_tI36lTGDUc,
  sentry_3AyO8nEfhE,
  vCalendar_client_FEmB2rjZFJ
]