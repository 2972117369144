import { z } from 'zod'

export const blockchainBigInt = z.string().regex(/^\d+$/)
export const blockchainAddress = z
  .string()
  .regex(
    /^0x[a-fA-F0-9]{40}$/,
    "Invalid wallet address: '0x' followed by 40 characters using 0-9 and A-F required",
  )
export const blockchainTx = z.string().regex(/^0x[a-fA-F0-9]{64}$/)
export const blockchainHash = blockchainTx
export const transactionHash = blockchainTx
export type TransactionHash = z.infer<typeof transactionHash>
export const phone = z.string().regex(/^\+?[0-9 -]+$/)
export const blockchainSignature = z.string().regex(/^0x[a-fA-F0-9]{130}$/)
export const website = z.string().regex(/^(|http(s?):\/\/[^\s]+)$/)
export const email = z.string().email()
export const countryISO2 = z.string().regex(/^[A-Z]{2}$/)
export const companyDocumentTypes = z.enum(['SOW', 'PSA'])
export type CompanyDocumentTypes = z.infer<typeof companyDocumentTypes>

export const isoDate = z
  .string()
  .regex(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format, please use YYYY-MM-DD')
  .refine(
    (dateStr) => {
      const [year, month, day] = dateStr.split('-').map(Number)
      if (year === undefined || month === undefined || day === undefined)
        return false

      const date = new Date(dateStr)

      if (Number.isNaN(date.getTime())) return false

      return (
        date.getUTCFullYear() === year &&
        date.getUTCMonth() + 1 === month &&
        date.getUTCDate() === day
      )
    },
    {
      message: 'Invalid date',
    },
  )
export type IsoDate = z.infer<typeof isoDate>
export const dateLike = z
  .union([z.number(), z.string(), z.date()])
  .pipe(z.coerce.date())
export const boolLike = z
  .union([z.number(), z.string(), z.boolean()])
  .pipe(z.coerce.boolean())
export const fetsFormData = z.object({
  file: z.any(),
  filename: z.string().min(1),
  mimetype: z.enum(['application/pdf', 'image/jpeg', 'image/png']),
})
export const recaptcha = z.object({
  'x-recaptcha-token': z.string().min(1),
  'x-recaptcha-action': z.string().min(1),
})
export const v1Auth = z.object({
  'x-auth-token': z.string().min(1),
})
export const pagination = z.object({
  total_records: z.coerce.number(),
  current_page: z.coerce.number(),
  total_pages: z.coerce.number(),
  next_page: z.coerce.number().nullable(),
  prev_page: z.coerce.number().nullable(),
})
export const encryptedWallet = z.object({
  address: z.string(),
  id: z.string(),
  version: z.number(),
  Crypto: z.object({
    cipher: z.string(),
    cipherparams: z.object({
      iv: z.string(),
    }),
    ciphertext: z.string(),
    kdf: z.string(),
    kdfparams: z.object({
      salt: z.string(),
      n: z.number(),
      dklen: z.number(),
      p: z.number(),
      r: z.number(),
    }),
    mac: z.string(),
  }),
  'x-ethers': z.object({
    client: z.string(),
    gethFilename: z.string(),
    path: z.string(),
    locale: z.string(),
    mnemonicCounter: z.string(),
    mnemonicCiphertext: z.string(),
    version: z.string(),
  }),
})
export const stipends = z
  .object({
    type: z.string(),
    amount: z.number(),
  })
  .array()
export type Stipends = z.infer<typeof stipends>

export const variableCompensationFrequency = z.enum([
  'annually',
  'quarterly',
  'monthly',
  'weekly',
])
export type VariableCompensationFrequency = z.infer<
  typeof variableCompensationFrequency
>

export const variableCompensationType = z.enum([
  'bonus',
  'commission',
  'reimbursement',
])
export type VariableCompensationType = z.infer<typeof variableCompensationType>

export const variableCompensations = z
  .object({
    name: z.string().min(1),
    type: variableCompensationType,
    frequency: variableCompensationFrequency,
    amount: z.number(),
  })
  .array()
export type VariableCompensations = z.infer<typeof variableCompensations>

export const payrollCurrencies = z.enum(['USD'])
export type PayrollCurrencies = z.infer<typeof payrollCurrencies>

export const taxQuestionnaireValues = z
  .object({
    parameter_name: z.string(),
    value: z.string(),
    unique_tax_id: z.string(),
    parameter_type: z.enum([
      'INTEGER',
      'DOLLAR',
      'BOOLEAN',
      'STRING',
      'PERCENTAGE',
    ]),
  })
  .array()
export type TaxQuestionnaireValues = z.infer<typeof taxQuestionnaireValues>

const paginationCursorFormat = z.object({
  idx: z.number(),
})
export const paginationCursor = z.string().transform((c) => {
  return paginationCursorFormat.parse(
    JSON.parse(Buffer.from(c, 'base64').toString()),
  )
})
export const currencyAmountCents = z.object({
  amount_cents: z.number(),
  currency: z.enum(['USD']),
})

export const notificationData = z.discriminatedUnion('name', [
  z.object({
    name: z.literal('TX_STATUS'),
    properties: z.object({
      status: z.enum(['waiting', 'confirmed', 'canceled', 'failed']),
    }),
  }),
])
export type NotificationData = z.infer<typeof notificationData>

export const blockchainTransactionsMetadata = z.object({
  nanoids: z.array(z.string()),
})
export type BlockchainTransactionsMetadata = z.infer<
  typeof blockchainTransactionsMetadata
>

export const defaultAddress = z.object({
  country: countryISO2,
  state: z.string(),
  city: z.string(),
  line_1: z.string(),
  line_2: z.string().nullish(),
  zip_code: z.string(),
})

export const integrationConnData = z.object({
  ledger_account_id: z.string(),
})

export type IntegrationConnData = z.infer<typeof integrationConnData>

export const payCycle = z.object({
  year: z.number().int().min(2020).max(2100),
  month: z.number().int().min(1).max(12),
  period: z.number().int().min(1).max(2),
})

export type PayCycle = z.infer<typeof payCycle>

export const fundingInstructions = z.object({
  beneficiary_address1: z.string(),
  beneficiary_address2: z.string().nullish(),
  beneficiary_city: z.string(),
  beneficiary_country: z.string(),
  beneficiary_name: z.string(),
  beneficiary_postal_code: z.string(),
  beneficiary_state_province_region: z.string(),
  account_number: z.string(),
  bank_address1: z.string(),
  bank_address2: z.string(),
  bank_city: z.string(),
  bank_country: z.string(),
  bank_name: z.string(),
  bank_postal_code: z.string(),
  bank_state_province_region: z.string(),
  currency: z.string(),
  message: z.string(),
  reference: z.string(),
  routing_code: z.string(),
  swift_bic: z.string().nullish(),
  tax_number: z.string().nullish(),
  type: z.string(),
})

export const integrationTranEntTypes = z.enum(['expense', 'invoice'])
export type IntegrationTranEntTypes = z.infer<typeof integrationTranEntTypes>

export const integrationAppNames = z.enum(['quickbook', 'xero'])
export type IntegrationAppNames = z.infer<typeof integrationAppNames>

export const risePayrollProgram = z.enum([
  'riseworks_inc',
  'riseworks_eor_us',
  'riseworks_pps_us',
])
export type RisePayrollProgram = z.infer<typeof risePayrollProgram>

export const paymentsByCategory = z.object({
  netpay: currencyAmountCents,
  grosspay: currencyAmountCents,
  extras: z.object({
    signing_bonus: currencyAmountCents,
    stipends: currencyAmountCents,
    one_off_bonuses: currencyAmountCents,
    variable_compensations: z.object({
      bonus: currencyAmountCents,
      commission: currencyAmountCents,
      reimbursement: currencyAmountCents,
    }),
  }),
  healthcare: z.object({
    employee: currencyAmountCents,
    employer: currencyAmountCents,
  }),
  retirement: z.object({
    employee: currencyAmountCents,
    employer: currencyAmountCents,
  }),
  taxes: z.object({
    employee: currencyAmountCents,
    employer: currencyAmountCents,
  }),
})
export type PaymentsByCategory = z.infer<typeof paymentsByCategory>

export const requestAttributes = z.object({
  params: z.record(z.string(), z.any()).nullable(),
  body: z.any().nullable(),
  query: z.record(z.string(), z.any()).nullable(),
})

export type RequestAttributes = z.infer<typeof requestAttributes>

const payCycleYearQueryParam = z
  .string()
  .refine(
    (val) => {
      const year = Number(val)
      const isNumber = !Number.isNaN(year) && val.trim() !== ''
      return isNumber && Number.isInteger(year) && year >= 2024
    },
    {
      message: 'Year must be an integer greater or equal 2024',
    },
  )
  .transform((val) => Number(val))

const payCycleMonthQueryParam = z
  .string()
  .refine(
    (val) => {
      const month = Number(val)
      const isNumber = !Number.isNaN(month) && val.trim() !== ''
      return isNumber && Number.isInteger(month) && month >= 1 && month <= 12
    },
    { message: 'Month must be an integer between 1 and 12' },
  )
  .transform((val) => Number(val))

const payCyclePeriodQueryParam = z
  .string()
  .regex(/^[12]$/)
  .transform((val) => Number(val))

export const payCycleQueryParams = z.object({
  year: payCycleYearQueryParam,
  month: payCycleMonthQueryParam,
  period: payCyclePeriodQueryParam,
})
