import { initializeApp } from "firebase/app";
import type { FirebaseApp, FirebaseOptions } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import type { GetTokenOptions, Messaging } from "firebase/messaging";
import { deleteDoc, doc, getDoc, getFirestore } from "firebase/firestore";

import notificationIcon from "./rise.png";

export type FirebaseConfig = {
  vapidKey: string;
} & FirebaseOptions;

export type InitFirebaseResponse = {
  deviceToken: string;
};

let firebaseApp: FirebaseApp;

async function registerSW(firebaseConfig: FirebaseOptions, appUrl: string) {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js"
      );
      console.log("Service Worker Registered");

      registration.active?.postMessage({
        type: "INIT_FIREBASE",
        firebaseConfig,
        notificationIcon,
        appUrl,
      });

      return registration;
    } catch (error) {
      console.error("Service Worker Registration Failed:", error);
    }
  }
}

export async function getFirebaseDB(userId: string) {
  if (firebaseApp) {
    const firestore = getFirestore(firebaseApp);
    const channel = new BroadcastChannel("push-notifications");

    try {
      const docRef = doc(firestore, "notifications", userId);
      const docSnapshot = await getDoc(docRef);

      if (!docSnapshot.exists()) {
        console.log(
          `No document found in collection "notifications" with ID "${userId}"`
        );
        return null;
      }

      console.log("Document Data:", docSnapshot.data());
      channel.postMessage(docSnapshot.data());

      await deleteDoc(docRef);
    } catch (error) {
      console.error("Error fetching document:", error);
      throw error;
    }
  }
}

export async function initFirebase(
  { vapidKey, ...firebaseConfig }: FirebaseConfig,
  appUrl: string,
  userId?: string
): Promise<InitFirebaseResponse> {
  if (!firebaseConfig) return;

  // initialize firebase app
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig);
  }

  // to support incognito and private modes
  const isBrowserSupported = await isSupported();
  if (
    (!isBrowserSupported || Notification.permission !== "granted") &&
    userId
  ) {
    await getFirebaseDB(userId);

    return;
  }

  // register service worker to listen to background notifications
  const serviceWorkerRegistration = await registerSW(firebaseConfig, appUrl);

  // request notification permission
  try {
    await Notification.requestPermission();
  } catch (error) {
    console.error("Requesting notification permission failed:", error);
  }

  // get device token
  let deviceToken: string | undefined;
  let messaging: Messaging | undefined;

  try {
    messaging = getMessaging(firebaseApp);
  } catch (error) {
    console.error("Failed to get firebase messaging:", error);
  }

  const tokenOptions: GetTokenOptions = {
    vapidKey,
  };

  if (serviceWorkerRegistration)
    tokenOptions.serviceWorkerRegistration = serviceWorkerRegistration;

  if (Notification.permission === "granted") {
    try {
      deviceToken = await getToken(messaging, tokenOptions);
    } catch (error) {
      console.error("Failed to get device token:", error);
    }
  }

  return {
    deviceToken,
  };
}
